import { initRiskVerify } from '@login/common/index.js'

export const riskVerifyChallenge  = async (response, params, cb ) => {
  let riskVerifyInstance = window?.LoginRiskVerifyInstance
  if(!riskVerifyInstance){
    riskVerifyInstance = await initRiskVerify()
  }
  const { validate_param = {} } = response?.info || {}
  Object.assign(response?.info, { validate_channel: validate_param?.channel })
  try {
    riskVerifyInstance?.doVerify(response?.info, ({ isSuccess, code, info }) => {
      if (isSuccess) {
        const { validate_token = ''  } = info || {}
        Object.assign(params, { validate_token, })
        cb({ paramsData: params, type: code })
        return
      }
      cb({ paramsData: params, type: code == 'close' ? 'close' : 'error'  })
    })
  } catch (error) {
    console.error('risk control verification failed', error)
    cb({ type: 'error' })
  }
}
